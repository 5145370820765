import React from "react";
import SEO from "../components/Seo/seo";
import { responsive } from '../utils/responsive';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { StyledMainContainer, StyledLeftContainer, StyledRightContainer, StyledImageWrapper, StyledDescription, StyledButtonWrapper, StyledTitle, StyledLeftContentContainer } from './head';
import FooterComponent from "../components/Home/Footer/FooterComponent";


// ---- Images ----

import FrameImage1 from '../components/imagesStock/Frame/FrameImage1';
import FrameImage2 from '../components/imagesStock/Frame/FrameImage2';
import FrameImage3 from '../components/imagesStock/Frame/FrameImage3';
import FrameImage4 from '../components/imagesStock/Frame/FrameImage4';


const FrameSubpage = props => {

    return (
        <Layout>
            <SEO title="Frame" />
            <StyledMainContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledTitle>{props.uri.replace(/[/]/g, "")}</StyledTitle>

                        <StyledDescription>
                            <h1>Top Section</h1>

                            <p>
                                The wooden frame supporting the entire head, including the core and the cover, is crafted from over 100-year-old structural beams.<br /><br />

                                The arms of the frame feature a suspended structure that maintains the light source in a central position. Horizontal elements are finished with brass sheets secured by rivets.
                            </p>

                        </StyledDescription>

                        <StyledButtonWrapper>
                            <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Back</h1></Link>
                        </StyledButtonWrapper>

                    </StyledLeftContentContainer>

                </StyledLeftContainer>

                <StyledRightContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledImageWrapper>
                            <FrameImage1 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <FrameImage2 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <FrameImage3 />
                        </StyledImageWrapper>

                        <StyledImageWrapper>
                            <FrameImage4 />
                        </StyledImageWrapper>

                    </Carousel>

                    <FooterComponent />

                </StyledRightContainer>

            </StyledMainContainer>

        </Layout>
    )
}

export default FrameSubpage;